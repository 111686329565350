import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 8; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/6-oct-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function HindiDiwas2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">हिंदी दिवस समारोह</h1>

        <p>
          भारत की राजभाषा हिंदी की महत्ता को प्रतिपादित करते हुए प्रतिवर्ष 14
          सितंबर के दिन हिंदी दिवस का आयोजन विद्यालय में किया जाता है। इस वर्ष
          भी हिंदी दिवस बड़े ही हर्षोल्लास से मनाया गया । जिसके अंतर्गत हिंदी
          भाषा के महत्त्व को दर्शाया गया । इसी क्रम में माध्यमिक एवं
          उच्च-माध्यमिक विभाग द्वारा सर्वप्रथम हिंदी भाषा के महत्त्व को
          प्रदर्शित करते हुए गीत का आयोजन किया गया। तत्पश्चात भारतीय संस्कृति
          एवं हिंदी के महत्त्व को बताते हुए एक नुक्कड़ नाटक का मंचन भी किया गया।
          इसी अवसर पर प्रधानाचार्या महोदया और प्रधान अध्यापिका ने अपने-अपने
          सुविचारों से विद्यार्थियों को सम्बोधित करते हुए भारतीय भाषाओं एवं
          बोलियों के महत्त्व को समझाया। राष्ट्रीय गीत के साथ समारोह का समापन
          हुआ। विद्यालय के प्राथमिक विभाग द्वारा इस अवसर पर सभी कक्षाओं में
          विभिन्न प्रकार की गतिविधियाँ करवाई गईं। जिसमें नारा लेखन, कविता लेखन,
          पोस्टर निर्माण आदि प्रमुख थीं।पूर्व प्राथमिक विभाग में शिक्षार्थियों
          ने हिंदी कहानी वाचन तथा कविता पाठ आदि गतिविधियों में भाग लिया तथा अपने
          वाक् कौशल तथा आत्मविश्वास का प्रदर्शन किया। बच्चों का यह प्रयास
          सराहनीय रहा ।
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
